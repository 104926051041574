.flatpickr-confirm {
  height: 40px;
  max-height: 0px;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--defaultTextColor) !important;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.flatpickr-confirm svg path {
  fill: inherit;
}

.flatpickr-confirm.darkTheme {
  color: var(--primaryWhite);
  fill: var(--primaryWhite);
}

.flatpickr-confirm.visible {
  max-height: 40px;
  visibility: visible;
  margin-top: -4px;
  background: var(--bgColor);
  color: var(--activeTextColor);
  font-weight: bold;
  z-index: 1;
  position: relative;
}

.flatpickr-calendar {
  background: transparent;
  -webkit-box-shadow: 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n + 1) .flatpickr-day.inRange:nth-child(7n + 7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n + 2) .flatpickr-day.inRange:nth-child(7n + 1) {
  -webkit-box-shadow: -2px 0 0 var(--primaryBorderColor), 5px 0 0 var(--primaryBorderColor);
  box-shadow: -2px 0 0 var(--primaryBorderColor), 5px 0 0 var(--primaryBorderColor);
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid var(--bgColor);
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border: 1px solid var(--bgColor);
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--bgColor);
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--bgColor);
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--bgColor);
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: var(--bgColor);
}

.flatpickr-months .flatpickr-month {
  background: var(--bgColor);
  color: var(--defaultTextColor);
  fill: var(--defaultTextColor);
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  color: var(--defaultTextColor);
  fill: var(--defaultTextColor);
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: var(--primaryError);
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--primaryError);
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper span {
  border: 1px solid rgba(72, 72, 72, 0.15);
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(72, 72, 72, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(72, 72, 72, 0.6);
}
.numInputWrapper span svg path {
  fill: rgba(90, 97, 113, 0.5);
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--defaultTextColor);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: var(--defaultTextColor);
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  font-size: 1rem;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  color: rgba(90, 97, 113, 0.5);
  background: transparent;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: var(--bgColor);
  font-size: 1rem;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: var(--bgColor);
}
.flatpickr-weekdays {
  background: var(--bgColor);
}

span.flatpickr-weekday {
  background: var(--bgColor);
  color: var(--defaultTextColor);
}
.flatpickr-days {
  border-left: 1px solid var(--bgColor);
  border-right: 1px solid var(--bgColor);
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 var(--bgColor);
  box-shadow: -1px 0 0 var(--bgColor);
}
.flatpickr-day {
  color: var(--defaultTextColor);
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: var(--accordionColor);
  border-color: var(--accordionColor);
}
.flatpickr-day.today {
  border-color: var(--primaryError);
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: var(--primaryError);
  background: var(--primaryError);
  color: #fff;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: var(--primaryErrorText);
  color: var(--activeTextColor);
  border-color: var(--primaryErrorText);
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 var(--primaryErrorText);
  box-shadow: -10px 0 0 var(--primaryErrorText);
}

.flatpickr-day.inRange {
  -webkit-box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2;
  box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(72, 72, 72, 0.3);
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  color: rgba(72, 72, 72, 0.1);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 var(--primaryErrorText), 5px 0 0 var(--primaryErrorText);
  box-shadow: -5px 0 0 var(--primaryErrorText), 5px 0 0 var(--primaryErrorText);
}

.flatpickr-weekwrapper .flatpickr-weeks {
  border-left: 1px solid var(--bgColor);
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
}
.flatpickr-innerContainer {
  background: var(--bgPrimary);
  border-bottom: 1px solid var(--bgColor);
}

.flatpickr-time {
  background: var(--paperColor);
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--defaultTextColor);
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: var(--defaultTextColor);
}

.flatpickr-time input {
  background: transparent;
  color: var(--defaultTextColor);
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  color: var(--defaultTextColor);
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: var(--accordionColor);
}
